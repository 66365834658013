<script setup lang="ts">
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"
import { LoginProps } from "./Login.props"

const isLogged = useIsLogged()

const { t } = useI18n()

const props = withDefaults(defineProps<LoginProps>(), {
  upperText: "",
  backgroundColor: "white",
  showLoginButton: false
})

const upperTextFinal = computed(() =>
  !!props.upperText ? props.upperText : t("loginBanner.type3.upperText")
)
</script>

<template>
  <div
    v-if="!isLogged"
    class="
      cart-utils-login
      border-y-2 border-black-10
      p-4
      md:rounded-lg
      md:border-none
      md:bg-green-10
      md:px-12
      md:py-6
      md:text-center
      lg:px-20
    "
    :class="[backgroundColor === 'white' ? 'bg-white' : 'bg-grey-main']"
  >
    <UtilsMarkdown
      :content="upperTextFinal"
      class="
        beaver
        markdown__link-underlined
        markdown__link-green-main
        markdown__link-beaver-medium
        md:pig
        md:markdown__link-black-main md:markdown__link-pig-medium
        mb-2
        md:mb-4
      "
    />

    <UtilsButton
      v-if="showLoginButton"
      :text="$t('cart.login')"
      class="mb-4 inline-block w-full md:mb-6 md:w-[200px]"
      @click="navigateToLoginSignupPage"
    />

    <UtilsMarkdown
      :content="t('loginBanner.type1.lowerText')"
      @click="navigateToLoginSignupPage"
      class="
        beaver
        markdown__strong-underlined markdown__strong-beaver-medium
        md:pig
        md:markdown__stron-black-main
        md:markdown__strong-pig-medium
        md:markdown__strong-hover-green-main
      "
    />
  </div>
</template>
